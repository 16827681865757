.main__div{
    padding: 1px;
    width: 350px;
    
}
.customer__head{
    display: flex;
    justify-content: space-around;
}
.customer__head__circle{
    background-color:lightgray;
    border: grey solid 1px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    font-size: 35px;
    text-transform: uppercase;
    
}
.customer__fullname{
    margin-left: 5px;
    margin-top: 10px;
    /* margin: auto; */
    /* word-wrap: break-word; */
/* white-space: pre-wrap; */
word-break: break-word;
/* align-items: center; */
}
.c-email{font-size: 15px;}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .main__div{
    width: 100%;
    
}
.customer__head{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.customer__head__circle{
    background-color:lightgray;
    border: grey 1px;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    font-size: 35px;
    text-transform: uppercase;
}
}
.title__details:hover{
    background-color: beige;
}
.title{
    font-size: 12px;
    text-transform: capitalize;
}
.title__details{
    /* background-color:aliceblue; */
    background-color:aliceblue;
    display: flex;
    align-items: center;
    height: 30px;
    /* flex-direction: row; */
    justify-content: space-between;
    /* align-items: flex-end; */
    /* word-break: break-word; */
}
/* .customer__title__details{
    background-color:red;
    justify-content: space-between;
} */
.nmi__edit{
  display: flex;
  justify-content: space-around;
}
.nmi__detail{
  justify-self: flex-start;
  width: 50%;
}
.nmi__button{
  width: 50%;
  display: flex;
  justify-content: flex-end;}
  .nmi__input:hover{
    border-bottom: 1px solid;
  }
   .nmi__input:focus{
    border-bottom: 1px solid;
  }