.homepage__header{
    background-color: var(--color-black);
    width: 100%;
}

.homepage__header-h1{
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    line-height: 70px;
    font-size: 90px;
}
.homepage__header-img img{
    width: 100%;

}

@media screen and (min-width: 2000px) {

}

@media screen and (max-width: 1150px) {
  .homepage__header-h1{
    font-family: var(--font-base);
    color: var(--color-golden);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    line-height: 40px;
    font-size: 50px;
    text-align: center;
    margin-right: .3em;
  }
}

@media screen and (max-width: 850px) {

}

@media screen and (max-width: 650px) {

}

@media screen and (max-width: 450px) {

}