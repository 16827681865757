:root {
    --input-color: #99a3ba;
    --input-border: #cdd9ed;
    --input-background: #fff;
    --input-placeholder: #cbd1dc;

    --input-border-focus: #275efe;

    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #eef4ff;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #678efe;
}

.search__box {
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 25px;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
    border-radius: 6px;
    -webkit-appearance: none;
    color: var(--input-color);
    border: 1px solid var(--input-border);
    background: var(--input-background);
    transition: border 0.3s ease;
    &::placeholder {
        color: var(--input-placeholder);
    }
    &:focus {
        outline: none;
        border-color: var(--input-border-focus);
    }
}

.search__table {
    position: relative;
    display: flex;
    width: 100%;
    & > span,
    .search__box{
        white-space: nowrap;
        display: block;
        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
        &:first-child {
            border-radius: 6px 0 0 6px;
        }
        &:last-child {
            border-radius: 0 6px 6px 0;
        }
        &:not(:first-child) {
            margin-left: -1px;
        }
    }
    .search__box {
        position: relative;
        z-index: 1;
        flex: 1 1 auto;
        width: 1%;
        margin-top: 0;
        margin-bottom: 0;
    }
    & > span {
        text-align: center;
        padding: 8px 12px;
        font-size: 14px;
        line-height: 25px;
        color: var(--group-color);
        background: var(--group-background);
        border: 1px solid var(--group-border);
        transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
    }
    &:focus-within {
        & > span {
            color: var(--group-color-focus);
            background: var(--group-background-focus);
            border-color: var(--group-border-focus);
        }
    }
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

// Center
body {
    min-height: 100vh;
    font-family: "Mukta Malar", Arial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #f5f9ff;
    .search__table{
        max-width: 360px;
        &:not(:last-child) {
            margin-bottom: 10px;
            margin-top:10px;
        }
    }
}
table{
    border-radius: 5px;
}
.table_tbody tr td{
    border:#cbd1dc solid 1px;
}
.jobtable_tbody tr {
    height: 50px;
}
.table__mainhead{
    position: sticky;
    top: 0;
    height: 50px;
    background-color: #cbd1dc;
    
}
.table__header{
justify-content: center;
text-transform: uppercase;
text-align: center;
//  border:#fff solid 1px;

}
// .table__mainhead tr th{
//     // border:#fff solid 1px;
// }
  .pagination {
    padding: 0.5rem;
    justify-content: center;
    // width: ;
    // .pagination__div__buttons{
    //     width: 500px;
    //     justify-content: space-between;
    // }
    button{ 
        border:#cbd1dc solid 1px;
        padding: 1px;
        width: 25px;
        margin: 3px;
        border-radius: 5px;
        
        
    }
    button:hover{
        background-color: #99a3ba;
    }
  }
  .text__results{
      display: flex;
      justify-content: center;
  }
  .page__textbox{
      width: 50px;
      border:#cbd1dc solid 1px;
      margin-right: 10px;
      background-color:#f5f9ff;
      border-radius: 5px;
      height: 30px;
  }
  .page__textbox:hover{
      background-color: #cbd1dc;

      
  }
  .page__border{
      background-color: #cbd1dc;
        width: 1px;
        height: 30px;
        margin-left: 3px;
        margin-right: 3px;
  }
  .data__count-options{
      border: none;
          width: 100px;
      border:#cbd1dc solid 1px;
      margin-right: 10px;
      background-color:#f5f9ff;
      border-radius: 5px;
      height: 30px;
  }
