.to__email__hidden{
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;
}
.to__email__visible{
    margin-left: 10px;
    overflow: visible;
    text-overflow: ellipsis;
    width: 70%;
}
.file__checkbox{
    width: 5%;
}
.customer__file__name{
    width: 25%;
}