@import '../../Styles/variable.scss';
.main__layout{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.container {
    // height: calc(100vh - #{$headerHeight});
    // background: blue;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    h1 {
        padding: 1.5rem 2.5rem;
        background: linear-gradient(220deg, $primary, rgba($primary, 0.5));
        border-radius: $borderRadius calc(#{$borderRadius} * 10);
    }
}
