.main__box{
display: flex;
justify-content: space-around;
height: 100%;
}
.head{
    background-color: black;
    color: white;
    width: 100%;
    text-align: center;
    margin: 5px 5px 5px 5px;
    font-size: 20px;

}
.leftItemList{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}
.leftItems{
    text-align: center;
    font-size: 18px;
    width: 100%;
    cursor: pointer;
}
.rightItemList{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 400px;
}
.rightItems{
    font-size: 18px;
    border-right: 1px solid;
    width: 50%;
    margin-left: 5px;
    cursor: pointer;
}
.rightItemsRight{
    font-size: 18px;
    width: 50%;
    margin-left: 5px;
    cursor: pointer;
}
.selected{
    background-color: lightblue ;
}
.x{float: right;
padding:0 5px 0 5px;
cursor: pointer;
border-left: 1px solid;
}
.x:hover{
    background-color: grey;
    color: white;
}
