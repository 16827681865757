*{
    margin: auto;
    padding: auto;
    box-sizing: border-box;
    font-family: montserrat, sans-serif;

}
input, button{
    appearance: none;
    background: none;
    border: none;
    outline: none;
}
.login__form__main{
    margin: 4rem;
    width: 400px;
}
.login__form{
    display: block;
    position: relative;
    margin: 1rem;
    z-index: 0;

}
.login__form::after{
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    right:  -5px;
    bottom: -5px;
    z-index: 0;
    /* background-color: blue; */
    background-image: linear-gradient(to bottom right, #ffce00, #fe4880 );
}
.app__form{
    position: relative;
    display: block;
    background-color: white;
    z-index: 1;
    padding: 30px;
}
.app__form h2{
   color: #888;
   font-size: 28px;
   font-weight: 500;
   margin-bottom: 30px;
}
.app__form .form-group{
display: block;
width: 300px;
margin-bottom: 15px;
}

.app__form .form-group label{
    display: block;
    columns: #666;
    font-size: 12px;
    margin-bottom: 5px;
    transition: 0.4s;
}
.app__form .form-group:focus-within label{
   columns: red;
}
.app__form .form-group input{
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 8px;
    transition: 0.4s;

}
.app__form .form-group input:focus{
 box-shadow: 0px, 0px, 3px, rgba(0, 0, 0, 0.2   ); 
}
.app__form input[type='submit'], .welcome button{
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #ffce00 20%, #ffce00, red);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    width: 180px;
}

.app__form input[type='submit']:hover, .welcome button:hover{
    background-position: 100% 0%;
}
.welcome{width: 100%;
    max-width: 480;
    background-color: white;
    padding: 30px;
}
.welcome h2{
    color: #888;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 30px;

}
.welcome h2 span{
    color: red;
    font-weight: 700;
}
.another__link{
    text-decoration: none;
}
.error__message{
    color: red;
}
.success__message{
    color: rgb(7, 233, 7);
}
.wrapper { position: relative; }
.eye__span{float: right;
        margin-right: 6px;
        margin-top: -35px;
        position: relative;
        z-index: 2;}