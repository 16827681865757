
.modal__div{
    width: 45em;
    position: relative;
}
.test{
    border: solid green;
}
.bg__even{
color: #000000;
}
.bg__odd{
background-color:azure;
}
.top__button{
    margin-top: 10px;
    margin-bottom: 10px;

}
.top__button button:first-child {
  width: 100px;
  margin-right: 10px;
}

.top__button button:last-child {
  flex-grow: 1;
}
.footer__button{
    margin-top: 10px;

}
.footer__button button:first-child {
  width: 100px;
  margin-right: 10px;
}

.footer__button button:last-child {
  flex-grow: 1;
}