.user__main__div{
  justify-content: center;
    align-items: center;
    /* text-align: center; */
    width: 100%;
    /* min-height:70px;   */
}
.main__usertable{ width: 100%;
    height: 10px;
}
.user__table__mainhead{
        position: sticky;
    top: 0;
    height: 50px;
    background-color: #cbd1dc;
}
.usertable__details{
    /* background-color: black; */
    width: 100%;
    height:100%;
    justify-content: space-between; 
    overflow: scroll;
}
.usertable__header{
    height: 10px;
    border: 1px solid black;
    justify-content: center;
text-transform: uppercase;
text-align: center;
}

.usertable_row{
    text-align: center;
    height: 50px;
    border: 0.5px solid;
}
.usertable__td{
border: 0.5px solid;
}
.role__span{
    cursor: pointer;
}
@media screen and (max-width: 800px) {
.user__main__div{
     font-size: 12px;
  white-space: nowrap; 
  /* text-overflow: ellipsis; */
    /* min-height:70px;   */
    width: 100%;
}

.usertable__header{
    height: 5px;
    justify-content: center;
text-transform: uppercase;
text-align: center;
}
}
@media screen and (max-width: 1000px) {

}