.app__main__navbar{
    width: 100%;
    display: flex;
    background: var(--color-black);
    justify-content: center;
    position: fixed;
    top:0;
    z-index: 2;
}

.app__navbar{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: var(--color-black); */
    padding: 0.5rem 2rem;

}

.app__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding: 0.5rem 2rem;
    margin-left: 100px; */
    margin-top: -8px;
    position: fixed;
}
.app__navbar-logo img{
    width: 50px;
    height: 65px;    
    
}
/* .nav__noimage{border: 1px solid; border-radius: 50px; width: 200px; height: 20px;} */
.company__dropdown img{border-radius: 50%; width: 25px; height: 25px;}
.app__main__logo{ width: 200px; height: 70px;}
.e4{margin-top: -1px; margin-left: 13px;}
.app__navbar-links{
    /* flex: 1; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    margin-top: 1rem;
    width: 100%;
    
    
}
.app__navbar-links li{
    margin: 0 0.5rem;
    cursor: pointer;
}

.app__navbar-links li:hover{
    color: var(--color-grey);
}
.app__navbar-login{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.app__navbar-login a{
   margin: 0 1rem;
   text-decoration: none;
   transition: 0.5s ease;
}
.app__navbar-login a:hover{
   border-bottom: 1px solid var(--color-golden);
}
.app__navbar-login div{
    width: 1px;
    height: 30px;
    background-color: var(--color-grey);
}
.app__navbar-smallscreen{
    display: none;
}
.app__navbar-smallscreen_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
}
.app__navbar-smallscreen_overlay .overlay__close{
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--color-golden);

}

.app__navbar-smallscreen_links{
    list-style: none;
    margin-top: -25vh;
}
/* .app_navbar__Link__bigscreen__bigscreen{font: 1em sans-serif;} */

.app__navbar-smallscreen_links li{
    margin: 1rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: start   ;
    /* font-family: var(--font-base); */
}
.app_navbar__Link__bigscreen{
          margin: .05rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 1.25rem;
    text-align: center;
    /* font-family: var(--font-base); */
    text-decoration: none;
}
.app_navbar__Link{
      margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 1.5rem;
    text-align: center;
    /* font-family: var(--font-base); */
    text-decoration: none;
}
.dropdown-toggle::after {
  display: none !important; 
}
.app_navbar__setting{
      margin: 1rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 1.5rem;
    text-align: center;
    /* font-family: var(--font-base); */
    text-decoration: none;
     box-shadow: "none",
    
}
.app_navbar__setting :focus-within {
      box-shadow: none
    }
/* .app__company__dropdown{ border: 2px solid red;} */
.app__company__dropdown :focus-within {
    box-shadow: none;
}
.small__screen{
    align-items: flex-start;
}
.app__navbar-smallscreen_links li:hover, .app_navbar__Link:hover, .app_navbar__Link__bigscreen:hover{
    color: var(--color-white);
}
.user__head{
    display: flex;
    justify-content: space-around;
}
.user__head__circle{
    background-color:lightgray;
    border: grey solid 1px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
    
}
.dp__name__cicle{
    text-transform: lowercase;
}
.user__name{
    color: white;
    margin-top: 5px;
    margin-left: 1px;
    width: 120px;
    height: 20px;
}
/* .arrow {
width: 20px;
	height: 20px;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-top: 50px solid #555;
} */

.user__name__down{border: 1px solid red;}
@media screen and (max-width:2000px){
    .app__navbar-logo img{
        width: 150px;
    }
  
}

@media screen and (max-width:1150px){
    .app__navbar-links{
        display: none;
    }
    .app__navbar-smallscreen{
        display: flex;
    }
}
@media screen and (max-width:650px){
    .app__navbar{
        padding: 1rem;

    }
       .app__navbar-logo img{
        width: 100px;
        height: 50px;
       
    }
  
}
@media screen and (max-width:290px){
    .app__navbar{
        padding: 1rem;

    }
       .app__navbar-logo img{
        width: 80px;
        height: 50px;
       
    }
  
}
