@import './variable.scss';
*{
  box-sizing: border-box;
  margin: 0;
}
body {
  width: 100%;
 font-family: $Poppins;
 color: $dark;
 padding-top: 80px; 

}
#root{
  height: 100%;
}


