@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,700&display=swap');
$Poppins: 'Poppins', sans-serif;

//colors
$dark: rgb(29,29,29);
$light:#fff;
$primary:rgb(162,162,246);
$bg:rgb(244,244,245);

//spacing
$spacing-md:16px;
$spacing-lg:23px;

//border radius
$borderRadius:12px;

//border height
$headerHeight:100px;

@mixin breakpoint($point) {
    @if $point == md{
        //786px
        @media(min-width: 48em){
            @content;
        }
    }
    
}

