.upload__button{
    /* background-color: blue; */
    display: flex;
    align-items: flex-end;
    align-content: space-around;
}
.button__upload__file{
color:darkcyan;
padding: 0.1rem;
}
.button__upload__file:hover{
padding: 0rem;
}
