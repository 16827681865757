@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
//Variables
$background: #fff1f1;
$red: #fb3958;
$font: 'Nunito', sans-serif;
$textc: #122125;
//Mixins
@mixin borders($s, $c){
	border: $s solid $c}
@mixin smallscreen{
	@media (max-width:650px){
		@content}}
//Layout
*{
	box-sizing: border-box;
	margin: 0;
	padding: 0}
html{
	height: 100%;}
body{
	background: $background;
	font-family: $font;}
//Main Styles
.container{
	width: 75%;
	max-width: 700px;
	margin: 1.5rem auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include smallscreen;
		width: 85%;
	.header{
		color: $red;
		font-size: 5em;
		font-weight: 700;
		text-align: center;
		text-shadow: 2px 2px 5px darken($red, 25%);
		@include smallscreen;
			font-size: 3em}}
.compcontainer{
	width: 75%;
	height: 13rem;
	padding: 1rem 0;
	@include smallscreen;
		height: 10rem;
	svg{
		max-width: 100%;
		max-height: 100%;
		animation: bouncy 1300ms linear infinite }}
.instructions{
	background: #FEFEFE;
	width: 80%;
	height: auto;
	padding: 1rem;
	@include borders(1px, #DCDCDC);
	border-radius: 0.25rem;
	@include smallscreen;
		width: 100%;
	h2{
		font-size: 1.25em;
		line-height: 1.3;
		color: darken($red, 15%);
		@include smallscreen;
			font-size: 1.05em;}
	p{
		font-size: 1.15em;
		line-height: 1.5;
		color: $textc;
		@include smallscreen;
			font-size: 1em;}
	.step{
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 1.5rem;
		margin: 0.5rem 0;
		.icon{
			width: 1.25rem;
			height: 1.25rem;
			align-self: center;
			@include smallscreen;
				width: 1rem;
				height: 1rem}
		p{
			display: inline-block;
			width: 80%;
			line-height: 1.5;
			padding-left: 0.5rem
		}
		
	}
	
}
	
@keyframes bouncy{
	0% {transform: translateY(10px) translateX(0) rotate(0)}
	25% {transform: translateX(-10px) rotate(-10deg)}
	50% {transform: translateX(0) rotate(0deg)}
	75% {transform: translateX(10px) rotate(10deg)}
	100% {transform: translateY(10px) translateX(0) rotate(0)}
}
