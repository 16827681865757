.main__table{ width: 100%;}
table{
    border-radius: 5px;
}
.job__main__div{
  justify-content: center;
    align-items: center;
    /* text-align: center; */
    width: 100%;
    /* min-height:70px;   */
}
.main__jobtable{ width: 100%;
    /* height: 10px; */
}
.job__table__mainhead{
        position: sticky;
    top: 0;
    /* height: 50px; */
    background-color: #cbd1dc;
}
.jobtable__details{
    /* background-color: black; */
    width: 100%;
    height:100%;
    justify-content: space-between; 
    overflow: scroll;
}
.jobtable__header{
    height: 10px;
    border:#cbd1dc solid 1px;
    justify-content: center;
text-transform: uppercase;
text-align: center;
padding: 5px;
}
.jobtable_tbody tr {
    height: 50px !important;
}
.jobtable_row{
    text-align: center;
    height: 50px ;
    white-space: nowrap;
}
.jobtable__td{
 border:#cbd1dc solid 1px;
}
.role__span{
    cursor: pointer;
}
/* .job_number__stick{
border-left: solid 1px #DDEFEF;
    left: 130;
    position: absolute;
    top: auto;
    /* width: 120px;  */
/* }  */

@media screen and (max-width: 800px) {
.job__main__div{
     font-size: 12px;
  white-space: nowrap; 
  /* text-overflow: ellipsis; */
    /* min-height:70px;   */
    width: 100%;
}

.jobtable__header{
    height: 5px;
    justify-content: center;
text-transform: uppercase;
text-align: center;
}
}
@media screen and (max-width: 1000px) {

}
.pagination{
    padding: 0.5rem;
    justify-content: center;
}

  .text__results{
      display: flex;
      justify-content: center;
  }
  .page__textbox{
      width: 50px;
      border:#cbd1dc solid 1px;
      margin-right: 10px;
      background-color:#f5f9ff;
      border-radius: 5px;
      height: 30px;
  }
  .page__textbox:hover{
      background-color: #cbd1dc;

      
  }
  .page__border{
      background-color: #cbd1dc;
        width: 1px;
        height: 30px;
        margin-left: 3px;
        margin-right: 3px;
  }
  .data__count-options{
      border: none;
          width: 100px;
      border:#cbd1dc solid 1px;
      margin-right: 10px;
      background-color:#f5f9ff;
      border-radius: 5px;
      height: 30px;
  }