.main__div{
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    width: 90%;
    min-height:700px;
}
.customer__details{
    /* background-color: black; */
    width: 100%;
    height:100%;
    justify-content: space-between; 
    overflow: scroll;
}
.bg__even{background-color: #ddd;}
.bg__even:hover{background-color: ghostwhite;}
.bg__odd:hover{background-color: ghostwhite;}
.scroll{
    width: 100%;
}
.text__wrap { width: 125px; white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}
.text__email__address { width: 220px; white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loading__img_div{
  text-align: center;
}