.customer__details_indv{
    width: 90%;
    display: flex;
    justify-content: space-between;
    /* height: 700px; */
}
  .customer__details_left, .customer__details_mid, .customer__details_right{
    margin: 0.5rem 0;
        width: 100%;
        padding: 1rem;
        border: lavender solid 1px;
         display: inline-block;
    
}
/* .main__div{
  overflow-y: scroll;
} */
.loading__img_div{
  margin-top: 100px;
  margin-bottom: 100px;
  min-height: 100vh;
  margin: auto;
}
.headings__all{
  font-weight: bold;
  color: cadetblue;
}
@media screen and (max-width: 800px) {
    .customer__details_indv{
      height:max-content ;
      align-items: center;
        flex-direction: column;
        padding: 0;
}
  .customer__details_left, .customer__details_mid, .customer__details_right{
    margin: 2rem 0;
        width: 100%;
        display: inline-block;
    
}
}