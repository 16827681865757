.dropdown__class{
    /* height: 1400px; */
    cursor: pointer;
}

.img__customerdetails{height: 50px;}
.input__label{
color: cornflowerblue;
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
}

.upload__details{
    /* background-color: red; */
    display: flex;
    justify-content:space-between;
}
.file__name{
    color: cadetblue;
    /* display: inline-block; */
    cursor: pointer;
      width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
    
}
.file__name__buttons{
display: flex;
width: 50%;
justify-content: flex-end;
align-items: center;
}

 .panels__serials{
   /* display: flex; */
   flex-direction: column;
 }
 .bg__serial__even{background-color: rgb(253, 242, 242);}
.bg__serial__even:hover{background-color: ghostwhite;}
.bg__serial__odd:hover{background-color: ghostwhite;}
.image__dr0pdown{
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.image__viewer{
  position: static
}
.uploaded{color: green;}
.notUploaded{color: red;}
.div__uploadMessage{display: flex; justify-content: space-around;}

@media screen and (max-width: 800px) {
.file__name{
    color: cadetblue;
    /* display: inline-block; */
    cursor: pointer;
      width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
    
}
.input__label{
color: cornflowerblue;
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap; 
  text-overflow: ellipsis;
}
}

/* The search field */
#searchIcon {
  margin: 0 5px 5px 5px;
  height: 25px;
}