.setting__main{
    width: 100%; 
    height: 100vh; 
    margin: 0; 
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: left;
}
.sidebar{
    /* flex: 15%; */
    width: 250px;
    
}
.dash{
    flex: 70%;
}
@media screen and (max-width: 800px) {

.container{
    width:80vw;
}

}
@media screen and (max-width: 560px) {
.container{
    width:60vw;
}
}